import React from "react";
import styled from "styled-components";
import Gallery from "../components/Gallery/gallery"
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Tours from "../components/Tours";


const TourItem = () => {

    return (
        <Layout>
            <SEO title="Fleet" description={"fleet"} />
            <main>
                <Wrapper>
                    <div>Tour Item </div>
                </Wrapper>
            </main>
        </Layout>
    );
};

const Wrapper = styled.div`
    height: 80vh;
`;

export default TourItem;
